import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { ReactComponent as YoutubeLogo } from '../../../assets/imgs/youtube-icon.svg';
import { ReactComponent as TwitterLogo } from '../../../assets/imgs/twitter-icon.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/imgs/instagram-icon.svg';
import { ReactComponent as GithubLogo } from '../../../assets/imgs/github-icon.svg';
import { ReactComponent as DiscordLogo } from '../../../assets/imgs/discord-icon.svg';
import { ReactComponent as TwitchLogo } from '../../../assets/imgs/twitch-icon.svg';
import { ReactComponent as LinkedinLogo } from '../../../assets/imgs/linkedin-icon.svg';

import './contact.scss';

export const Contact: React.FC = () => {
  return (
    <div className='contact'>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <h1>Contact</h1>
      <hr />
      <div className='faq-notice'>
        Before contacting me have a look at the <Link className='link' to={'/FAQ'}>FAQ</Link> for some commonly asked questions!
      </div>
      <h2>Socials</h2>
      <hr />
      <div className='socials'>
        <a href='https://twitch.tv/johnsongnow' target='_blank' rel="noreferrer">
          <div className='social'>
            <TwitchLogo/>
          </div>
        </a>
        <a href='https://youtube.com/c/JohnSong' target='_blank' rel="noreferrer">
          <div className='social'>
            <YoutubeLogo/>
          </div>
        </a>
        <a href='https://twitter.com/JohnSongNow' target='_blank' rel="noreferrer">
          <div className='social'>
            <TwitterLogo/>
          </div>
        </a>
        <a href='https://instagram.com/JohnSongNow' target='_blank' rel="noreferrer">
          <div className='social'>
            <InstagramLogo/>
          </div>
        </a>
        {
            <a href='https://discord.com/invite/a7YcSeuH9H' target='_blank' rel="noreferrer">
            <div className='social'>
              <DiscordLogo/>
            </div>
          </a>
        }
        {
          <a href='https://www.linkedin.com/in/johnsongnow/' target='_blank' rel="noreferrer">
            <div className='social'>
              <LinkedinLogo/>
            </div>
          </a>
        }
        <a href='https://github.com/JohnSongNow' target='_blank' rel="noreferrer">
          <div className='social'>
            <GithubLogo/>
          </div>
        </a>
      </div>
    </div>
  )
}
