import React from 'react';

import './theme-picker.scss';

type Props = {
  onClick: (theme: string) => void;
}

export const ThemePicker: React.FC<Props> = ({ onClick }) => {
  return (
    <div className='theme-picker'>
      <div className="theme-button" onClick={(e) => onClick('dark')} style={{ backgroundColor: 'black' }}></div>
      <div className="theme-button" onClick={(e) => onClick('light')} style={{ backgroundColor: '#a8d0e6' }}></div>
      <div className="theme-button" onClick={(e) => onClick('neon')} style={{ backgroundColor: '#cb2d6f' }}></div>
    </div>
  )
}
