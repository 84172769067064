import React from 'react';
import { Helmet } from 'react-helmet';
import { GameItem } from '../game-item/game-item';

import './games.scss';

export const Games: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Games</title>
      </Helmet>
      <h1>Games</h1>
      <hr/>
      <div className='games'>
        <GameItem title={'Group the Tiles: Redux'} caption={'2D Puzzle Game'} imgLink={'https://user-images.githubusercontent.com/11955347/210160548-fa98aff2-7e4d-4f7b-b690-5e180fcf9f7c.png'} imgAltTitle={'Divide the Tiles: Redux'}
          link={'https://github.com/Ctrlfyp/group-the-tiles'} />
      </div>
    </div>
  )
}
