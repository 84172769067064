import React from 'react';
import { Link } from 'react-router-dom';

import './navigation-bar.scss';

export const NavigationBar: React.FC = () => {
return (
    <div className='nav-bar'>
      <div className='nav-bar-content'>
        <ul>
          <li>
            <Link className='link' to={'/'}>Home</Link>
          </li>
          <li>
            <a className='link' href={'https://github.com/JohnSongNow/JohnSongNow/blob/master/README.md'} target='_blank' rel="noreferrer">About</a>
          </li>
          <li>
            <a className='link' href={'https://youtube.com/c/JohnSong'} target='_blank' rel="noreferrer">Videos</a>
          </li>
          <li>
            <a className='link' href={'https://twitch.tv/JohnSongNow'} target='_blank' rel="noreferrer">Stream</a>
          </li>
          <li>
            <Link className='link' to={'/Games'}>Games</Link>
          </li>
          <li>
            <a className='link' href={'https://twitch.tv/JohnSongNow'} target='_blank' rel="noreferrer">Stream</a>
          </li>
          <li>
            <Link className='link' to={'/Contact'}>Contact</Link>
          </li>
          <li>
            <a className='link' href={'/John_Song_Resume.pdf'} target='_blank' rel="noreferrer">Resume</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
