import React from 'react';

import './game-item.scss';

type Props = {
  title: string;
  caption: string;
  imgLink: string;
  imgAltTitle: string;
  link: string;
  onImageLoaded?: () => void;
}

export const GameItem: React.FC<Props> = ({
  title,
  caption,
  imgLink,
  imgAltTitle,
  link,
  onImageLoaded
}) => {
  return (
    <div className='game'>
      <a href={link} target='_blank' rel="noreferrer">
        <img
          src={imgLink}
          alt={imgAltTitle}
          onLoad={onImageLoaded}
        ></img>
        <div className='caption'>
          <h4>{title}</h4>
          <hr />
          <p>{caption}</p>
        </div>
      </a>
    </div>
  )
}
