import React from 'react';
import { Helmet } from 'react-helmet';

export const Other: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Other</title>
      </Helmet>
      <h1>Other</h1>
      <hr/>
      <h2>Nothing here now!</h2>
    </div>
  )
}
