import React from 'react';

import './landing.scss';

export const Landing: React.FC = () => {

  return (
    <div className='landing'>
      <div className='landing-about-info'>
        <h1>
          Hi, I'm John Song 👋
        </h1>
        <h3>
          Software Developer, Hobbyist Game Dev, and Youtube creator!
        </h3>
      </div>
    </div>
  )
}
