import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { Contact } from "../../contact/components/contact";
import { Faq } from "../../faq/components/faq";
import { Games } from "../../games/components/games/games";
import { Landing } from '../../landing/components/landing';
import { Other } from '../../other/components/other';
import { NavigationBar } from '../../navigation-bar/components/navigation-bar';
import { ThemePicker } from "../../theme-picker/components/theme-picker";

import './App.scss';

export const App: React.FC = () => {

  const app = React.useRef(null);
  const [theme, setTheme] = React.useState('dark');
  React.useEffect(() => {
    const rootElement = ((app.current as unknown as Node).getRootNode() as Document).documentElement;
    rootElement.setAttribute('class', theme);
  }, [app, theme])

  const onThemeSet = (theme: string) => {
    setTheme(theme);
  }

  return (
    <div className="App" ref={app}>
      <Helmet defaultTitle="John Song" titleTemplate="%s - John Song">
      </Helmet>
      <Router>
        <NavigationBar/>
          <div className='content'>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/stream" component={() => { window.location.href = 'https://twitch.tv/johnsongnow'; return null; }} />
              <Route path="/videos" component={() => { window.location.href = 'https://youtube.com/c/johnsong'; return null; }} />
              <Route path="/games" component={Games} />
              <Route path="/faq" component={Faq} />
              <Route path="/contact" component={Contact} />
              <Route path="/other" component={Other} />
              <Redirect from="*" to="/" />
            </Switch>
          </div>
        <ThemePicker onClick={onThemeSet}/>
      </Router>
    </div>
  );
}

export default App;
