import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import './faq.scss'

export const Faq: React.FC = () => {
  return (
    <div className='faq'>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <h1>FAQ</h1>
      <hr/>
      <ul className='qa-list'>
        <li className='qa'>
          <div className='q'>
            Are you ever going to make cs/game dev related videos?
          </div>
          <div className='a'>
            Hmm no plan at the moment to go back and make this type of videos! Maybe in the future!
          </div>
        </li>
        <li className='qa'>
          <div className='q'>
            What do you use to make your videos?
          </div>
          <div className='a'>
            <a href='https://www.blackmagicdesign.com/products/davinciresolve' target='_blank' rel="noreferrer">Davinci Resolve</a>, <a href='https://krita.org/en/' target='_blank' rel="noreferrer">Krita</a>, <a href='https://www.audacityteam.org/' target='_blank' rel="noreferrer">Audacity</a>, and <a href='https://obsproject.com/'  target='_blank' rel="noreferrer">OBS</a>
          </div>
        </li>
        <li className='qa'>
          <div className='q'>
            Video suggestion
          </div>
          <div className='a'>
            If you have any video idea's you can either send me an email at johnsongpast@gmail.com or visit the <Link className='link' to={'/https://github.com/JohnSongNow/youtube-videos'}>Discord</Link>
          </div>
        </li>
        <li className='qa'>
          <div className='q'>
            What do you use to make your games?
          </div>
          <div className='a'>
            <a href='https://unity.com/' target='_blank' rel="noreferrer">Unity</a>, I don't/can't do any of the artist potions 
          </div>
        </li>
      </ul>
    </div>
  )
}
